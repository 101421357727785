import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IAddress } from '@shared/interfaces/address.interface';
import { Address } from '@shared/models/address.model';
import { AddressesSerializer } from '@shared/serializers/addresses.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddressesService {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/addresses`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<IAddress> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Address(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IAddress> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Address(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public findByLocation(locationId: string): Observable<IAddress> {
    return this._http.get(`${this.endpoint}/location/${locationId}`).pipe(
      map((res: any) => new Address(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public patch(a: Partial<IAddress>): Observable<IAddress> {
    const data = AddressesSerializer.serialize(a);
    return this._http.patch(`${this.endpoint}/${a.id}`, data).pipe(
      map((res: any) => new Address(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(c: Partial<IAddress>): Observable<IAddress> {
    const data = AddressesSerializer.serialize(c);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Address(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }
}
