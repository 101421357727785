import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { LoggerService } from '@shared/services/logger.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html'
})
export class ContentComponent implements OnInit, OnDestroy {
  @ViewChild('header', { static: false })
  public headerEl: ElementRef;

  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public counter: number;
  @Input() public description: string;
  @Input() public pills: { label: string; class: string }[] = [];
  @Input() public iconClass: string;
  @Input() public badge: { label: string; class: string };
  @Input() public isSticky = Boolean(false);
  @Input() public colLeftSize = String('col-md-4');
  @Input() public colRightSize = String('col-md-8');

  public isStickyTop: boolean;

  private readonly constructorName: string = String(this.constructor.name);

  private sidebarScrollSubscription: Subscription;

  constructor(
    private readonly _logger: LoggerService,
    private readonly _sidebar: SidebarService
  ) {}

  ngOnInit(): void {
    const url = 'Sidebar scroll';
    this.sidebarScrollSubscription =
      this._sidebar.scrollSidebarCalled$.subscribe(
        () => {
          // this._logger.info(this.constructorName, url, res);

          if (!!this.headerEl) {
            const rect = this.headerEl.nativeElement.getBoundingClientRect();
            const top = rect.top;

            this.isStickyTop = top === 0;
          }
        },
        (err: any) => this._logger.error(this.constructorName, url, err)
      );
  }

  ngOnDestroy(): void {
    this.sidebarScrollSubscription?.unsubscribe();
  }
}
