import { getSerializer } from '@shared/serializers/serializer';
import { events } from './events.serializer';
import { locations } from './locations.serializer';

export const reports = {
  type: 'report',
  attributes: [
    'name',
    'startDate',
    'endDate',
    'locations',
    'parentLocations',
    'events',
    'notes',
    'createdAt',
    'data',
  ],
  locations: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  },
  parentLocations: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  },
  events: {
    ref: 'id',
    attributes: events.attributes,
    included: false
  }
};

export const ReportsSerializer = getSerializer(reports);
