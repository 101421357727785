import { Serializer } from 'ts-jsonapi';

export const getSerializer = (data: { type: string; attributes: string[] }) =>
  new Serializer(data.type, {
    id: 'id',
    keyForAttribute: 'camelCase',
    nullIfMissing: true,
    pluralizeType: false,
    ...data
  });
