import { locations } from '@shared/serializers/locations.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { users } from '@shared/serializers/users.serializer';

export const actions = {
  type: 'action',
  attributes: [
    'user',
    'location',
    'label',
    'pictures',
    'isSendEmailSelected',
    'description',
    'priority',
    'createdAt',
    'completedAt',
    'updatedAt'
  ],
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  location: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  }
};

export const ActionsSerializer = getSerializer(actions);
