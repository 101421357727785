import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IItemGroup } from '@shared/interfaces/item-group.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { ItemGroup } from '@shared/models/item-group.model';
import { ItemGroupsSerializer } from '@shared/serializers/item-groups.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ItemGroupsService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/item-groups`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<IItemGroup> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new ItemGroup(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IItemGroup> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new ItemGroup(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IItemGroup[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((ig: IItemGroup) => new ItemGroup(ig))
      ),
      catchError(errorHandler)
    );
  }

  public patch(itemGroup: Partial<IItemGroup>): Observable<IItemGroup> {
    const data = ItemGroupsSerializer.serialize(itemGroup);
    return this._http.patch(`${this.endpoint}/${itemGroup.id}`, data).pipe(
      map((res: any) => new ItemGroup(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(itemGroup: IItemGroup): Observable<IItemGroup> {
    const data = ItemGroupsSerializer.serialize(itemGroup);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new ItemGroup(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }
}
