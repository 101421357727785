import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICompany } from '@shared/interfaces/company.interface';
import { IError } from '@shared/interfaces/error.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { ITemplate } from '@shared/interfaces/template.interface';
import { Company } from '@shared/models/company.model';
import { CompaniesService } from '@shared/services/companies.service';
import { EmployeesService } from '@shared/services/employees.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LoggerService } from '@shared/services/logger.service';
import { TemplatesService } from '@shared/services/templates.service';
import jwtDecode from 'jwt-decode';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-templates',
  templateUrl: './modals.templates.component.html'
})
export class ModalsTemplatesComponent
  extends ModalDirective<ITemplate>
  implements OnInit
{
  @Input() public openModal: Subject<ITemplate>;

  public defaultCompanyValue: string;
  public defaultUserValue: string;

  public title = String('MODALS.TEMPLATES.ADD.TITLE');
  public description = String('MODALS.TEMPLATES.ADD.DESCRIPTION');
  public entry: FormGroup;
  public disableButtons = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;

  public role: number;

  public companies: ICompany[] = [];

  public errors: IError[] = [];

  public employeesFilters: IFilter[] = [
    {
      key: 'role',
      value: [1]
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    public readonly _companies: CompaniesService,
    public readonly _employees: EmployeesService,

    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _router: Router,
    private readonly _templates: TemplatesService
  ) {
    super();

    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((t: ITemplate) => {
      const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
      this.role = Number(payload.role);

      this.deleteKey = null;

      if (t) {
        this.defaultCompanyValue = t.company.name;
        this.defaultUserValue = t.user.fullName;

        if (t.id) {
          this.title = 'MODALS.TEMPLATES.EDIT.TITLE';
          this.description = 'MODALS.TEMPLATES.EDIT.DESCRIPTION';
        } else {
          this.title = 'MODALS.TEMPLATES.ADD.TITLE';
          this.description = 'MODALS.TEMPLATES.ADD.DESCRIPTION';
        }

        this.entry.patchValue(t);
        this.deleteKey = 'BUTTONS.DELETE';
      }

      if (this.role === 1) {
        this.entry.patchValue({
          company: {
            id: payload.company
          },
          user: {
            id: payload.id
          }
        });
      }

      // TO DO: IMPLEMENT TEMPLATE LANGUAGE IN A FUTURE RELEASE
      this.entry.patchValue({
        language: 'en'
      });
    });
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /templates/${id}`;

    this._templates.delete(id).subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, url, res);

        this._router.navigate(['/templates']);
        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /locations`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /locations/${value.id}`;
      }

      this._templates[method](value).subscribe(
        (res: ITemplate) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal(res);
          this.entry.enable();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          const errors = err.errors;

          if (errors) {
            this.errors = errors;
          }

          this.entry.enable();
        }
      );
    }
  }

  public onGetValueFromAttributes(e: any) {
    if (e instanceof Company) {
      return e.name;
    } else {
      return `${e.fullName} ${e.email ? '(' + e.email + ')' : null}`;
    }
  }

  public onMatOptionSelect(e: any): void {
    let type = 'user';
    let id = e.id;
    if (e instanceof Company) {
      type = 'company';
    } else {
      id = e.user.id;
    }

    this.entry.get(type).patchValue({
      id
    });
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      label: ['', [Validators.required]],
      // TO DO Make language selectable
      language: ['en', [Validators.required]],
      company: this._fb.group({
        id: ['', [Validators.required]]
      }),
      user: this._fb.group({
        id: ['', [Validators.required]]
      })
    });
  }
}
