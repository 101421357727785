import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { dataURLtoBlob } from '@shared/helpers/b64.helper';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IDocument } from '@shared/interfaces/document.interface';
import { Document } from '@shared/models/documents';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  public refreshSource = new Subject();

  public refreshCalled$ = this.refreshSource.asObservable();

  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/documents`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public refresh() {
    this.refreshSource.next();
  }

  public delete(id: string): Observable<IDocument> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Document(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    type: string,
    id?: string,
    pdfTemplate = 'default'
  ): Observable<Blob> {
    let url = this.endpoint;

    let httpParams = new HttpParams();

    if (type === 'template') {
      httpParams = httpParams.append('type', id);
      url += `/template`;
    } else if (
      type === 'pdf' ||
      type === 'csv' ||
      type === 'qrcode' ||
      type === 'eventsQrcode'
    ) {
      httpParams = httpParams.append('type', type);
      url += `/${id}`;
    }

    httpParams = httpParams.append('pdfTemplate', pdfTemplate);

    const options: any = {
      params: httpParams
    };

    if (type === 'pdf' || type === 'document') {
      options.responseType = 'blob';
    } else if (
      type === 'csv' ||
      type === 'template' ||
      type === 'qrcode' ||
      type === 'eventsQrcode'
    ) {
      options.responseType = 'text';
    }

    return this._http.get(url, options).pipe(
      map((res: any) => {
        switch (type) {
          case 'qrcode':
          case 'eventsQrcode':
            return dataURLtoBlob(res);

          case 'pdf':
            return new Blob([res], { type: 'application/pdf' });

          case 'template':
          case 'csv':
          case 'document':
            return new Blob([res]);
          default:
        }
      }),
      catchError(errorHandler)
    );
  }

  public post(
    file: File,
    params: any,
    isCheck?: boolean
  ): Observable<IDocument> {
    const formData: FormData = new FormData();
    formData.append('file', file, uuid());

    let url = this.endpoint;
    if (isCheck) {
      url += '/check';
    } else {
      url += `/forms/${params.id}/items`;
    }

    const options = {
      params
    };

    return this._http
      .post(url, formData, options)
      .pipe(map((res: any) => new Document(deserializer.deserialize(res))));
  }
}
