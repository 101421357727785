import { companies } from '@shared/serializers/companies.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { users } from '@shared/serializers/users.serializer';

export const employees = {
  type: 'employee',
  attributes: [
    'company',
    'user',
    'role',
    'isDeleted',
    'createdAt',
    'updatedAt'
  ],
  company: {
    ref: 'id',
    attributes: companies.attributes,
    included: false
  },
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  }
};

export const EmployeesSerializer = getSerializer(employees);
