import { IAddress } from '@shared/interfaces/address.interface';
import { ILocation } from '@shared/interfaces/location.interface';

export class Address implements IAddress {
  public id: string;
  public location: Partial<ILocation>;
  public unit: string;
  public name?: string;
  public line1: string;
  public line2: string;
  public city: string;
  public postalCode: string;
  public province: string;
  public country: string;
  public lat: number;
  public lng: number;

  get complete() {
    if (!this.line1 && !this.city) {
      return '-';
    }

    return `${this.unit} ${this.line1}, ${this.city} ${this.province}, ${this.postalCode}`;
  }

  constructor(partial: Partial<IAddress>) {
    Object.assign(this, partial);
  }
}
