import moment from 'moment-timezone';

export const getFormattedDate = (
  date: number,
  recurrence: string,
  step?: any,
  isCompleted?: boolean
): string => {
  date = date * 1000;

  switch (recurrence) {
    case 'y':
    case 'M':
      return moment(date).format('MMM YYYY');

    case 'w':
      let startOf = moment(date).startOf('isoWeek').format('MMM DD,');
      let endOf = moment(date).endOf('isoWeek').format('MMM DD, YYYY');

      if (step > 1) {
        startOf = moment(date)
          .add(step, recurrence)
          .startOf('isoWeek')
          .format('MMM DD,');
        endOf = moment(date)
          .add(step, recurrence)
          .endOf('isoWeek')
          .format('MMM DD, YYYY');
      }

      return `${startOf} to ${endOf}`;

    case 'h':
      if (isCompleted) {
        return moment(date).format('LLL');
      }

      let startHour: any = moment(date);
      const endHour = moment(startHour)
        .add(step, recurrence)
        .set('m', 0)
        .format('HH:mm');
      startHour = startHour.set('m', 0).format('HH:mm');

      return `${startHour} to ${endHour}`;

    case 'd':
    default:
      if (step > 1) {
        return moment(date).add(step, recurrence).format('ll');
      }

      return moment(date).format('ll');
  }
};
