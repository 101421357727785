import { locations } from '@shared/serializers/locations.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { templates } from '@shared/serializers/templates.serializer';
import { users } from '@shared/serializers/users.serializer';

export const qrcodes = {
  type: 'qrcode',
  attributes: [
    'notifyees',
    'user',
    'location',
    'template',
    'items',
    'label',
    'step',
    'isDailyDigestEnabled',
    'isDeleted',
    'createdAt',
    'updatedAt'
  ],
  notifyees: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  location: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  },
  template: {
    ref: 'id',
    attributes: templates.attributes,
    included: false
  }
};

export const QRCodeSerializer = getSerializer(qrcodes);
