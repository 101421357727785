import { AppComponent } from '@admin/app.component';
import { CoreModule } from '@admin/core/core.module';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environment/environment';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.IS_PRODUCTION,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
})
export class AppModule {}
