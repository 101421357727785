import { Injectable } from '@angular/core';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Filter } from '@shared/models/filter.model';
import { ChecklistsService } from '@shared/services/checklists.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationChecklistsService extends PaginationService<IChecklist> {
  private static readonly DEFAULT_START_DATE: IFilter = {
    key: 'startDate',
    value: ''
  };

  private static readonly DEFAULT_DUE_DATE: IFilter = {
    key: 'endDate',
    value: ''
  };

  private static readonly DEFAULT_IS_DELETED_FILTER: IFilter = {
    label: 'COMMON.ACTIVE',
    key: 'isDeleted',
    value: 'false'
  };

  private static readonly DEFAULT_STATUS_FILTER: IFilter = {
    label: 'FILTERS.NOT_REVIEWED',
    key: 'reviewedBy',
    value: 'false'
  };

  private static readonly DEFAULT_ALL_FILTER: IFilter = {
    label: 'FILTERS.ALL',
    key: 'all',
    value: 'false'
  };

  private static readonly DEFAULT_MISSED_FILTER: IFilter = {
    label: 'FILTERS.MISSED',
    key: 'missed',
    value: 'false'
  };

  private static readonly DEFAULT_SEARCH_FILTER: IFilter = {
    key: 'search',
    value: null
  };

  private static readonly DEFAULT_PARENT_LOCATION_FILTER: IFilter = {
    key: 'parent',
    value: null
  };

  private static readonly DEFAULT_SUB_LOCATION_FILTER: IFilter = {
    key: 'location',
    value: null
  };
  private static readonly DEFAULT_IS_FROM_DASHBOARD_FILTER: IFilter = {
    key: 'isFromDashboard',
    value: false
  };


  private _filtersScope: any;

  constructor(_paginable: ChecklistsService, _logger: LoggerService) {
    super(_paginable, _logger);

    this.setFilters([
      PaginationChecklistsService.DEFAULT_IS_DELETED_FILTER,
      PaginationChecklistsService.DEFAULT_STATUS_FILTER,
      PaginationChecklistsService.DEFAULT_SEARCH_FILTER,
      PaginationChecklistsService.DEFAULT_PARENT_LOCATION_FILTER,
      PaginationChecklistsService.DEFAULT_START_DATE,
      PaginationChecklistsService.DEFAULT_DUE_DATE,
      PaginationChecklistsService.DEFAULT_SUB_LOCATION_FILTER,
      PaginationChecklistsService.DEFAULT_ALL_FILTER,
      PaginationChecklistsService.DEFAULT_MISSED_FILTER,
      PaginationChecklistsService.DEFAULT_IS_FROM_DASHBOARD_FILTER
    ]);
  }

  public getFilters(): IFilter[] {
    return this._filtersScope;
  }

  public reset() {
    super.reset();

    this.setFilters([
      PaginationChecklistsService.DEFAULT_IS_DELETED_FILTER,
      PaginationChecklistsService.DEFAULT_STATUS_FILTER,
      PaginationChecklistsService.DEFAULT_SEARCH_FILTER,
      PaginationChecklistsService.DEFAULT_PARENT_LOCATION_FILTER,
      PaginationChecklistsService.DEFAULT_START_DATE,
      PaginationChecklistsService.DEFAULT_DUE_DATE,
      PaginationChecklistsService.DEFAULT_ALL_FILTER,
      PaginationChecklistsService.DEFAULT_MISSED_FILTER,
      PaginationChecklistsService.DEFAULT_IS_FROM_DASHBOARD_FILTER
    ]);
  }

  public setFilters(filters: Partial<IFilter>[]): void {
    this._filtersScope = filters.map((f) => new Filter(f));
    super.setFilters(this._filtersScope);
  }
}
