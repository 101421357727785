import { ICompany } from '@shared/interfaces/company.interface';
import { IEmployee } from '@shared/interfaces/employee.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Company } from '@shared/models/company.model';
import { User } from '@shared/models/user.model';

export class Employee implements IEmployee {
  public id: string;
  public company: Partial<ICompany>;
  public user: Partial<IUser>;

  public role: number;
  public isDeleted: boolean;

  public createdAt: Date;
  public updatedAt: Date;
  public score: number;

  // TO DO
  // NOT IMPLEMENTED
  // get score() {
  //   return 'n/a';
  // }

  get userId() {
    return this.user.id;
  }

  get fullName() {
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  get email() {
    return this.user.email;
  }

  get pillsRole(): any {
    let label;
    let className;

    switch (this.role) {
      case 0:
        label = 'Admin';
        className = 'ohq-badge-primary';
        break;

      case 1:
        label = 'Manager';
        className = 'ohq-badge-warning';
        break;

      case 2:
        label = 'Analytics';
        className = 'ohq-badge-light';
        break;

      case 3:
        label = 'User';
        className = 'ohq-badge-success';
        break;

      default:
        label = '-';
        className = 'ohq-badge-primary';
        break;
    }

    return {
      label,
      class: className
    };
  }

  get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }

  constructor(partial: Partial<IEmployee>) {
    Object.assign(this, {
      ...partial,
      company:
        typeof partial?.company === 'object'
          ? new Company(partial.company)
          : partial?.company,
      user:
        typeof partial?.user === 'object'
          ? new User(partial.user)
          : partial?.user
    });
  }
}
