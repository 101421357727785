import { pictures } from '@shared/serializers/pictures.serializer';
import { getSerializer } from '@shared/serializers/serializer';

export const users = {
  type: 'user',
  attributes: [
    'firstName',
    'lastName',
    'picture',
    'email',
    'phone',
    'countryCode',
    'password',
    'role',
    'isActive',
    'verifiedCreated',
    'resetToken',
    'resetExpires',
    'connectedAt',
    'createdAt',
    'updatedAt'
  ],
  picture: {
    ref: 'id',
    attributes: pictures.attributes,
    included: false
  }
};

export const UsersSerializer = getSerializer(users);
