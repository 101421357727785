import { Component, Input, OnInit } from '@angular/core';
import { TableColType } from '@shared/enums/table-col-type.enum';
import { ITableCol } from '@shared/interfaces/table-col.interface';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class TableComponent implements OnInit {
  @Input() public title: string;
  @Input() public type: string;
  @Input() public cols: ITableCol[];
  @Input() public rows: any[][];
  @Input() public pagination: PaginationService<any>;

  @Input() public onButtonClick: (
    e: Event,
    row: any,
    iteration?: number
  ) => void;
  @Input() public onRowClick: (row: any) => void;

  public TableColType = TableColType;

  constructor() {}

  ngOnInit(): void {
    if (this.pagination) {
      this.pagination.checkAndLoadPage(this.pagination.pagination.page);
    }
  }

  public processRow(col: ITableCol, row: any) {
    const cell = col.selector
      .split('.')
      .reduce((prev, current) => prev[current], row);
    return col.selectorFunction ? col.selectorFunction(cell) : cell;
  }

  public processTooltip(col: ITableCol, row: any) {
    const cell = col.selector
      .split('.')
      .reduce((prev, current) => prev[current], row);
    return col.tooltipFunction ? col.tooltipFunction(cell) : null;
  }

  public trackByFn(_i: any, r: any) {
    return r.id;
  }

  public pageChanged(event: any): void {
    this.pagination.pagination.page = event.page;
    this.pagination.checkAndLoadPage(this.pagination.pagination.page);
  }
}
