import { Injectable } from '@angular/core';
import { IEmployee } from '@shared/interfaces/employee.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Filter } from '@shared/models/filter.model';
import { EmployeesService } from '@shared/services/employees.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationEmployeesService extends PaginationService<IEmployee> {
  private static readonly DEFAULT_IS_DELETED_FILTER: IFilter = {
    label: 'COMMON.ACTIVE',
    key: 'isDeleted',
    value: 'false'
  };

  private static readonly DEFAULT_SEARCH_FILTER: IFilter = {
    key: 'search',
    value: null
  };

  private _filtersScope: any;

  constructor(_paginable: EmployeesService, _logger: LoggerService) {
    super(_paginable, _logger);

    this.setFilters([
      PaginationEmployeesService.DEFAULT_IS_DELETED_FILTER,
      PaginationEmployeesService.DEFAULT_SEARCH_FILTER
    ]);
  }

  public getFilters(): IFilter[] {
    return this._filtersScope;
  }

  public reset() {
    super.reset();

    this.setFilters([
      PaginationEmployeesService.DEFAULT_IS_DELETED_FILTER,
      PaginationEmployeesService.DEFAULT_SEARCH_FILTER
    ]);
  }

  public setFilters(filters: Partial<IFilter>[]): void {
    this._filtersScope = filters.map((f) => new Filter(f));
    super.setFilters(this._filtersScope);
  }
}
