import { companies } from '@shared/serializers/companies.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { users } from '@shared/serializers/users.serializer';

export const templates = {
  type: 'template',
  attributes: [
    'label',
    'user',
    'company',
    'language',
    'itemsCount',
    'createdAt',
    'updatedAt'
  ],
  company: {
    ref: 'id',
    attributes: companies.attributes,
    included: false
  },
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  }
};

export const TemplatesSerializer = getSerializer(templates);
