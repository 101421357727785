import { Injectable } from '@angular/core';
import { IActivity } from '@shared/interfaces/activity.interface';
import { ActivitiesService } from '@shared/services/activities.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationActivitiesService extends PaginationService<IActivity> {
  constructor(_paginable: ActivitiesService, _logger: LoggerService) {
    super(_paginable, _logger);
  }
}
