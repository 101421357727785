import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {
  public title = String('NOTFOUND.TITLE');
  public description = String('NOTFOUND.DESCRIPTION');

  constructor() {}

  ngOnInit(): void {}
}
