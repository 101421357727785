import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environment/environment';
import moment from 'moment-timezone';

@Pipe({
  name: 'ago'
})
export class AgoPipe implements PipeTransform {
  transform(value: any): any {
    return moment(value).tz(environment.TZ).fromNow();
  }
}
