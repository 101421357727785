import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IAction } from '@shared/interfaces/action.interface';
import { IActivity } from '@shared/interfaces/activity.interface';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { IDocument } from '@shared/interfaces/document.interface';
import { Document } from '@shared/models/documents';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Action } from '@shared/models/action.model';
import { Activity } from '@shared/models/activity.model';
import { Checklist } from '@shared/models/checklist.model';
import { ChecklistsSerializer } from '@shared/serializers/checklists.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ITask } from '@shared/interfaces/task.interface';
import { Task } from '@shared/models/task.model';

@Injectable({
  providedIn: 'root'
})
export class ChecklistsService {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/checklists`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<IChecklist> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Checklist(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IChecklist> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Checklist(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IChecklist[]> {
    let httpParams = new HttpParams();

    if (!!pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (!!filters) {
      filters.map((f: IFilter) => {
        if (!!f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((e: IChecklist) => new Checklist(e))
      ),
      catchError(errorHandler)
    );
  }

  public getActions(id: string): Observable<IAction[]> {
    return this._http.get(`${this.endpoint}/${id}/actions`).pipe(
      map((res: any) =>
        deserializer.deserialize(res).map((a: IAction) => new Action(a))
      ),
      catchError(errorHandler)
    );
  }

  public getTasks(id: string): Observable<ITask[]> {
    return this._http.get(`${this.endpoint}/${id}/tasks`).pipe(
      map((res: any) =>
      deserializer.deserialize(res).map((a: ITask) => new Task(a))
      ),
      catchError(errorHandler)
    );
  }

  public getActivities(id: string): Observable<IActivity[]> {
    return this._http.get(`${this.endpoint}/${id}/activities`).pipe(
      map((res: any) =>
        deserializer.deserialize(res).map((a: IActivity) => new Activity(a))
      ),
      catchError(errorHandler)
    );
  }

  public getChecklistsReport(filters?: any,): Observable<any[]> {
    let httpParams = new HttpParams();
    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }
    return this._http.get(this.endpoint+'/dashboard/report', { params: httpParams }).pipe(
      map((res: any) => res),
      catchError(errorHandler)
    );
  }

  public getUserBarReport(filters?: any): Observable<any[]> {
    let httpParams = new HttpParams();
    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }
    return this._http.get(this.endpoint+'/dashboard/users-report', { params: httpParams }).pipe(
      map((res: any) => res),
      catchError(errorHandler)
    );
  }

  public getUsersReport(filters?: any): Observable<any[]> {
    let httpParams = new HttpParams();
    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }
    return this._http.get(this.endpoint+'/dashboard/user-performance-table-report', { params: httpParams }).pipe(
      map((res: any) => res),
      catchError(errorHandler)
    );
  }

  public getMapaData(filters?: any,): Observable<any[]> {
    let httpParams = new HttpParams();
    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }
    return this._http.get(this.endpoint+'/dashboard/map-location-report', { params: httpParams }).pipe(
      map((res: any) => res),
      catchError(errorHandler)
    );
  }

  public patch(c: any): Observable<IChecklist> {
    const data = ChecklistsSerializer.serialize(c);
    return this._http.patch(`${this.endpoint}/${c.id}`, JSON.parse(JSON.stringify(data))).pipe(
      map((res: any) => new Checklist(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(c: Partial<IChecklist>): Observable<IChecklist> {
    const data = ChecklistsSerializer.serialize(c);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Checklist(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public postDocument(file: File, filters: IFilter[]): Observable<IDocument> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let httpParams = new HttpParams();
    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }
    return this._http
      .post(`${this._commonEnvironments.API_ENDPOINT}/pictures`, formData, {
        params: httpParams
      })
      .pipe(map((res: any) => new Document(deserializer.deserialize(res))));
  }
}
