import { ModalsCompaniesComponent } from '@admin/shared/components/modals/companies/modals.companies.component';
import { ModalsEmailsComponent } from '@admin/shared/components/modals/emails/modals.emails.component';
import { ModalsEmployeesComponent } from '@admin/shared/components/modals/employees/modals.employees.component';
import { NavbarComponent } from '@admin/shared/components/navbar/navbar.component';
import { TopbarComponent } from '@admin/shared/components/topbar/topbar.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { UiSwitchModule } from 'ngx-ui-switch';

const components = [
  NavbarComponent,
  TopbarComponent,

  // Modals
  ModalsEmailsComponent,
  ModalsEmployeesComponent,
  ModalsCompaniesComponent,
];

const modules = [UiSwitchModule];

@NgModule({
  declarations: [...components],
  imports: [SharedModule, ...modules],
  exports: [SharedModule, ...modules, ...components]
})
export class AdminSharedModule { }
