export const roleList = [
  {
    label: 'Access Control',
    value: 'Access Control'
  },
  {
    label: 'Electrician',
    value: 'Electrician'
  },
  {
    label: 'Emergency Generator',
    value: 'Emergency Generator'
  },
  {
    label: 'Elevators',
    value: 'Elevators'
  },
  {
    label: 'Fire Monitoring',
    value: 'Fire Monitoring'
  },
  {
    label: 'Fire Safety',
    value: 'Fire Safety'
  },
  {
    label: 'Glazing',
    value: 'Glazing'
  },
  {
    label: 'Graffiti Removal',
    value: 'Graffiti Removal'
  },
  {
    label: 'Landscaper',
    value: 'Landscaper'
  },
  {
    label: 'HVAC',
    value: 'HVAC'
  },
  {
    label: 'Locksmith',
    value: 'Locksmith'
  },
  {
    label: 'Painting',
    value: 'Painting'
  },
  {
    label: 'Pest Control',
    value: 'Pest Control'
  },
  {
    label: 'Restoration',
    value: 'Restoration'
  },
  {
    label: 'Roofing',
    value: 'Roofing'
  },
  {
    label: 'Snow Removal',
    value: 'Snow Removal'
  },
  {
    label: 'Waste Disposal & Recycling',
    value: 'Waste Disposal & Recycling'
  },
  {
    label: 'Window Cleaning',
    value: 'Window Cleaning'
  }
];












export const roleTypes = [
  {
    label: 'Root',
    value: 0
  },
  {
    label: 'User',
    value: 1
  }
];

export const employeeRolesTypes = [
  {
    label: 'Admin',
    value: 0
  },
  {
    label: 'Manager',
    value: 1
  },
  // NOT USED
  // {
  //   label: 'Analytics',
  //   value: 2
  // },
  {
    label: 'Employee',
    value: 3
  }
];

export const itemTypes = [
  {
    label: 'Yes / No',
    value: 0
  },
  {
    label: 'Text',
    value: 1
  },
  {
    label: 'Number',
    value: 2
  },
  {
    label: 'Date',
    value: 3
  },
  {
    label: 'Signature',
    value: 4
  },
  {
    label: 'Telephone',
    value: 5
  },
  {
    label: 'Dropdown',
    value: 6
  }
];

export const itemValues = [
  {
    label: 'Yes',
    value: '1'
  },
  {
    label: 'No',
    value: '2'
  },
  {
    label: 'n/a',
    value: '0'
  }
];

export const eventTypes = [
  {
    label: 'All events',
    value: 'all'
  },
  {
    label: 'Not completed',
    value: 'notCompleted'
  },
  {
    label: 'Verify',
    value: 'verify'
  },
  {
    label: 'Completed',
    value: 'completed'
  },
  {
    label: 'Archived',
    value: 'archived'
  }
];

export const priorities = [
  {
    label: 'Low',
    value: 0
  },
  {
    label: 'Medium',
    value: 1
  },
  {
    label: 'High',
    value: 2
  }
];

export const recurrences = [
  {
    label: 'One-time',
    value: 'o'
  },
  {
    label: 'Hourly',
    value: 'h'
  },
  {
    label: 'Daily',
    value: 'd'
  },
  {
    label: 'Weekly',
    value: 'w'
  },
  {
    label: 'Monthly',
    value: 'M'
  },
  {
    label: 'Annually',
    value: 'y'
  }
];

export const recurrenceForSteps = [
  {
    label: 'Hours',
    value: 'h'
  },
  {
    label: 'Weeks',
    value: 'w'
  },
  {
    label: 'Months',
    value: 'M'
  }
];


export const taskRecurrences = [
  {
    label: 'None',
    value: 'n'
  },
  {
    label: 'Daily',
    value: 'd'
  },
  {
    label: 'Yearly',
    value: 'y'
  }
];

export const userTypes = [
  {
    label: 'Employee',
    value: 'employee'
  },
  {
    label: 'Contractor',
    value: 'contractor'
  }
];

export const userContactFilterTypes = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Contractor',
    value: 'CONTRACTOR'
  },
  {
    label: 'Occupant',
    value: 'TENANT'
  }
];

export const userContactTypes = [
  {
    label: 'Contractor',
    value: 'CONTRACTOR'
  },
  {
    label: 'Occupant',
    value: 'TENANT'
  }
];

export const createLoginTypes = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];
