import { PhoneNumber } from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js/min';

export const phoneFormat = (
  countryCode: string,
  phone: PhoneNumber | string
): string => {
  if (!phone) {
    return '-';
  }

  const parserdPhoneNumber = parsePhoneNumber(`+${countryCode}${phone}`);

  let phoneNumber = '';
  if (countryCode === '1') {
    phoneNumber = parserdPhoneNumber.formatNational();
  } else {
    phoneNumber = parserdPhoneNumber.formatInternational();
  }

  return phoneNumber;
};
