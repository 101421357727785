import { ICompany } from '@shared/interfaces/company.interface';
import { IItemGroup } from '@shared/interfaces/item-group.interface';

export class ItemGroup implements IItemGroup {
  public id: string;
  public company: Partial<ICompany>;

  public label: string;
  public order: number;
  public itemsCount: number;
  public isDeleted: boolean;

  public createdAt: Date;
  public updatedAt: Date;

  constructor(partial: Partial<IItemGroup>) {
    Object.assign(this, partial);
  }
}
