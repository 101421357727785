import { Injectable } from '@angular/core';
import { ILocation } from '@shared/interfaces/location.interface';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationSublocationsService extends PaginationService<
  ILocation
> {
  constructor(_paginable: LocationsService, _logger: LoggerService) {
    super(_paginable, _logger);
  }
}
