import { getSerializer } from '@shared/serializers/serializer';

export const addresses = {
  type: 'address',
  attributes: [
    'location',
    'unit',
    'name',
    'line1',
    'line2',
    'city',
    'postalCode',
    'province',
    'country',
    'lat',
    'lng',
    'createdAt',
    'updatedAt'
  ]
};

export const AddressesSerializer = getSerializer(addresses);
