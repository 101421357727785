import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser } from '@shared/interfaces/user.interface';
import { AuthenticationService } from '@shared/services/authentication.service';
import { LoggerService } from '@shared/services/logger.service';
import { UpdatesService } from '@shared/services/updates.service';
import { UsersService } from '@shared/services/users.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
})
export class TopbarComponent implements OnInit, OnDestroy {
  public user: IUser;

  public links: { icon: string; label: string; url: string }[] = [
    {
      icon: 'lnr-exit',
      label: 'logout',
      url: '/logout'
    }
  ];

  public isUpdateAvailable = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  private updateSubscription: Subscription;

  constructor(
    private readonly _authentication: AuthenticationService,
    private readonly _logger: LoggerService,
    private readonly _users: UsersService,
    private readonly _updates: UpdatesService
  ) {
    const urlUpdate = 'Update subscription';
    this.updateSubscription = this._updates.updatesCalled$.subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, urlUpdate, res);

        this.isUpdateAvailable = res;
      },
      (err: any) => this._logger.error(this.constructorName, urlUpdate, err)
    );
  }

  ngOnInit(): void {
    this.getMyself();
  }

  ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe();
  }

  public reload() {
    window.location.reload();
  }

  private getMyself() {
    const url = `GET /users/me`;

    this._users.getMyself().subscribe(
      (res: IUser) => {
        this._logger.info(this.constructorName, url, res);

        this.user = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this._authentication.logout().subscribe();
      }
    );
  }
}
