import { IEmail } from '@shared/interfaces/email.interface';

export class Email implements IEmail {
  public id: string;
  public type: string;
  public subject: string;
  public title: string;
  public body: string;
  public cta: string;
  public url: string;

  constructor(partial: Partial<IEmail>) {
    Object.assign(this, partial);
  }
}
