import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IAuthentication } from '@shared/interfaces/authentication.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Authentication } from '@shared/models/authentication.model';
import { AuthenticationSerializer } from '@shared/serializers/authentication';
import { UsersSerializer } from '@shared/serializers/users.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { PaginationActionsService } from '@shared/services/pagination/pagination.actions.service';
import { PaginationActivitiesService } from '@shared/services/pagination/pagination.activities.service';
import { PaginationChecklistsService } from '@shared/services/pagination/pagination.checklists.service';
import { PaginationEmployeesService } from '@shared/services/pagination/pagination.employees.service';
import { PaginationEventChecklistsService } from '@shared/services/pagination/pagination.event.checklists.service';
import { PaginationEventsService } from '@shared/services/pagination/pagination.events.service';
import { PaginationItemsService } from '@shared/services/pagination/pagination.items.service';
import { PaginationLocationsService } from '@shared/services/pagination/pagination.locations.service';
import { PaginationSublocationsService } from '@shared/services/pagination/pagination.sublocations.service';
import { PaginationTemplatesService } from '@shared/services/pagination/pagination.templates.service';
import { PaginationUsersService } from '@shared/services/pagination/pagination.users.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/auth`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _router: Router,
    private readonly _http: HttpClient,

    // Pagination
    private readonly _paginationActions: PaginationActionsService,
    private readonly _paginationActivities: PaginationActivitiesService,
    private readonly _paginationChecklists: PaginationChecklistsService,
    private readonly _paginationEmployees: PaginationEmployeesService,
    private readonly _paginationEventChecklists: PaginationEventChecklistsService,
    private readonly _paginationEvents: PaginationEventsService,
    private readonly _paginationItems: PaginationItemsService,
    private readonly _paginationLocations: PaginationLocationsService,
    private readonly _paginationSubLocations: PaginationSublocationsService,
    private readonly _paginationTemplates: PaginationTemplatesService,
    private readonly _paginationUsers: PaginationUsersService
  ) {}

  public getReset(token: string): Observable<IAuthentication> {
    return this._http.get(`${this.endpoint}/reset/${token}`).pipe(
      map((res: any) => new Authentication(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public login(u: Partial<IUser>): Observable<IAuthentication> {
    const data = UsersSerializer.serialize(u);
    return this._http.post(`${this.endpoint}/login`, data).pipe(
      map((res: any) => new Authentication(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public logout() {
    return this._http.delete(`${this.endpoint}/logout`).pipe(
      map(
        () => {
          this._paginationActions.reset();
          this._paginationActivities.reset();
          this._paginationChecklists.reset();
          this._paginationEmployees.reset();
          this._paginationEvents.reset();
          this._paginationEventChecklists.reset();
          this._paginationItems.reset();
          this._paginationLocations.reset();
          this._paginationSubLocations.reset();
          this._paginationTemplates.reset();
          this._paginationUsers.reset();

          this._commonEnvironments.cleanStorage();

          return this._router.navigate(['/login']);
        },
        catchError((err: any) => err)
      )
    );
  }

  public postForget(u: Partial<IUser>): Observable<IAuthentication> {
    const data = UsersSerializer.serialize(u);
    return this._http.post(`${this.endpoint}/forget`, data).pipe(
      map((res: any) => new Authentication(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public postReset(u: Partial<IUser>): Observable<IAuthentication> {
    const data = AuthenticationSerializer.serialize(u);
    return this._http.post(`${this.endpoint}/reset`, data).pipe(
      map((res: any) => new Authentication(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public sendEmailVerify(u: Partial<IUser>): Observable<IAuthentication> {
    const data = UsersSerializer.serialize(u);
    return this._http.post(`${this.endpoint}/verify/${u.id}`, data).pipe(
      map((res: any) => new Authentication(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public resolve() {
    return this.logout();
  }
}
