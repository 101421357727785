import { companies } from '@shared/serializers/companies.serializer';
import { locations } from '@shared/serializers/locations.serializer';
import { getSerializer } from '@shared/serializers/serializer';

export const contacts = {
  type: 'contact',
  attributes: [
    'company',
    'locations',
    'userType',
    'createLogin',
    'role',
    'companyName',
    'firstName',
    'lastName',
    'email',
    'phone',
    'countryCode',
    'createdAt',
    'updatedAt',
    'files'
  ],
  company: {
    ref: 'id',
    attributes: companies.attributes,
    included: false
  },
  locations: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  }
};

export const ContactsSerializer = getSerializer(contacts);
