import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { ICompanySetting } from '@shared/interfaces/company-setting.interface';
import { ICompany } from '@shared/interfaces/company.interface';
import { IContact } from '@shared/interfaces/contact.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { CompanySetting } from '@shared/models/company-setting.model';
import { Company } from '@shared/models/company.model';
import { Contact } from '@shared/models/contact.model';
import { CompaniesSerializer } from '@shared/serializers/companies.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import jwtDecode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService implements IPaginable {
  @LocalStorage() public companySettings: ICompanySetting;

  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/companies`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<ICompany> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Company(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<ICompany> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Company(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public getLogo(id: string): Observable<any> {
    return this._http.get(`${this.endpoint}/${id}/logo`).pipe(
      map((res: any) => res),
      catchError(errorHandler)
    );
  }

  public getCompanySettings(
    id: string,
    isSaved: boolean = false
  ): Observable<ICompanySetting> {
    return this._http.get(`${this.endpoint}/${id}/settings`).pipe(
      map((res: any) => {
        const companySettings = new CompanySetting(
          deserializer.deserialize(res)
        );

        if (isSaved) {
          this.companySettings = companySettings;
        }

        return companySettings;
      }),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<ICompany[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((c: ICompany) => new Company(c))
      ),
      catchError(errorHandler)
    );
  }

  public patch(c: Partial<ICompany>): Observable<ICompany> {
    const data = CompaniesSerializer.serialize(c);
    return this._http.patch(`${this.endpoint}/${c.id}`, data).pipe(
      map((res: any) => new Company(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(c: Partial<ICompany>): Observable<ICompany> {
    const data = CompaniesSerializer.serialize(c);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Company(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public postContacts(id: string, file: File): Observable<IContact[]> {
    const formData: FormData = new FormData();
    formData.append('file', file, uuid());

    return this._http.post(`${this.endpoint}/${id}/contacts`, formData).pipe(
      map((res: any) =>
        deserializer.deserialize(res).map((ic: IContact) => new Contact(ic))
      ),
      catchError(errorHandler)
    );
  }

  public resolve(): Observable<ICompanySetting> {
    const token = this._commonEnvironments.getToken();
    const payload: IPayload = jwtDecode(token);
    return this.getCompanySettings(payload.company, true);
  }
}
