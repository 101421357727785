import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IEvent } from '@shared/interfaces/event.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Event } from '@shared/models/event.model';
import { EventsSerializer } from '@shared/serializers/events.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventsService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/events`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<IEvent> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Event(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IEvent> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Event(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IEvent[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((e: IEvent) => new Event(e))
      ),
      catchError(errorHandler)
    );
  }

  public patch(e: Partial<IEvent>): Observable<IEvent> {
    const data = EventsSerializer.serialize(e);
    return this._http.patch(`${this.endpoint}/${e.id}`, data).pipe(
      map((res: any) => new Event(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(e: IEvent): Observable<IEvent> {
    const data = EventsSerializer.serialize(e);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Event(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }
}
