import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { itemTypes, itemValues } from '@shared/helpers/types.helper';
import { IError } from '@shared/interfaces/error.interface';
import { IItemGroup } from '@shared/interfaces/item-group.interface';
import { IItemOption } from '@shared/interfaces/item-option.interface';
import { IItem } from '@shared/interfaces/item.interface';
import { ItemGroupsService } from '@shared/services/item-groups.service';
import { ItemsService } from '@shared/services/items.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-items',
  templateUrl: './modals.items.component.html'
})
export class ModalsItemsComponent
  extends ModalDirective<IItem>
  implements OnInit
{
  @Input() public openModal: Subject<IItem>;

  public title = String('MODALS.ITEMS.ADD.TITLE');
  public description = String('MODALS.ITEMS.ADD.DESCRIPTION');
  public entry: FormGroup;

  public isButtonsDisabled = Boolean(false);
  public isOptionsAvailable = Boolean(false);
  public disableButtons = Boolean(false);

  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;

  public types: { value: number; label: string }[] = itemTypes;
  public yesNoDefaultValues: any[] = itemValues;

  public errors: IError[] = [];
  public itemGroups: IItemGroup[] = [];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _items: ItemsService,
    private readonly _itemGroups: ItemGroupsService,
    private readonly _toasts: ToastService
  ) {
    super();
  }

  public get formArrayOptions(): FormArray {
    return this.entry.get('itemOptions') as FormArray;
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((i: IItem) => {
      this.getItemGroups();

      if (i) {
        if (i.id) {
          this.title = 'MODALS.ITEMS.EDIT.TITLE';
          this.description = 'MODALS.ITEMS.EDIT.DESCRIPTION';
          this.deleteKey = 'BUTTONS.DELETE';
        } else {
          this.title = 'MODALS.ITEMS.ADD.TITLE';
          this.description = 'MODALS.ITEMS.ADD.DESCRIPTION';
          this.deleteKey = null;
        }

        (this.entry.get('itemOptions') as FormArray).clear();

        if (i.itemOptions?.length > 0) {
          i.itemOptions.map((o: any) => this.addItem('itemOptions', o));
        }

        this.entry.patchValue({
          ...i,
          itemGroup: {
            id: i.itemGroup
          }
        });
      } else {
        this.entry.patchValue({
          type: ''
        });
      }
    });
  }

  public changeType() {
    const type = this.entry.get('type').value;
    const optionsField = this.formArrayOptions as FormArray;

    // get rid of options
    optionsField.clear();
    optionsField.clearValidators();

    if (type === 6) {
      [0, 1].map(() => {
        this.addItem('itemOptions');
      });

      optionsField.setValidators([
        Validators.required,
        Validators.minLength(2)
      ]);
    }

    optionsField.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /items/${id}`;

    this._items.delete(id).subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /items`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /items/${value.id}`;
      }

      this._items[method](value).subscribe(
        (res: IItem) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal();
          this.entry.enable();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          const errors = err.errors;

          if (errors) {
            this.errors = errors;

            if (errors[0].detail) {
              this._toasts.error(errors[0].detail);
            }
          }

          this.entry.enable();
        }
      );
    }
  }

  public addItem(type: string, o?: any) {
    const options = this.entry.get(type) as FormArray;
    options.push(this.createItem(o));
  }

  public deleteItem(type: string, i: number) {
    const options = this.entry.get(type) as FormArray;
    options.removeAt(i);
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      label: ['', [Validators.required]],
      type: ['', [Validators.required]],
      order: ['', [Validators.required]],
      defaultValue: [''],
      itemOptions: this._fb.array([]),
      itemGroup: this._fb.group({
        id: ['', [Validators.required]]
      }),
      template: this._fb.group({
        id: ['', [Validators.required]]
      })
    });
  }

  private createItem(o?: IItemOption) {
    return this._fb.group({
      id: [o?.id || ''],
      label: [o?.label || '', [Validators.required]]
    });
  }

  private getItemGroups() {
    const filters = [
      {
        key: 'pagination',
        value: 'false'
      }
    ];

    const url = `GET /item-groups`;
    this._itemGroups.get(1, filters).subscribe(
      (res: IItemGroup[]) => {
        this._logger.info(this.constructorName, url, res);
        this.itemGroups = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
