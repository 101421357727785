import packageInfo from '../../package.json';

export const environment = {
  VERSION: packageInfo.version,
  TZ: 'America/Los_Angeles',
  AIRBRAKE: {
    ENVIRONMENT: 'staging',
    ADMIN: {
      PROJECT_ID: 272263,
      PROJECT_KEY: '56cf02a7f451eaa6adee4cb5f86a4707'
    },
    APP: {
      PROJECT_ID: 272262,
      PROJECT_KEY: 'e99f72008dc0efd9d9cb19ff26ce1992'
    }
  },
  SOCKET_ENDPOINT: 'https://staging.api.onsitehq.co',
  API_ENDPOINT: 'https://staging.api.onsitehq.co/v3',
  CDN_ENDPOINT: 'https://staging.assets.onsitehq.co',
  GA_API: {
    domain: 'auto',
    trackingId: 'UA-123775847-3'
  },
  IS_PRODUCTION: false,
  ENVIRONMENT: 'staging'
};

