import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environment/environment';
import moment from 'moment-timezone';

@Pipe({
  name: 'date'
})
export class DatesPipe implements PipeTransform {
  transform(date: string, args?: string): string {
    const m = moment(date, moment.ISO_8601, true).tz(environment.TZ);

    if (m.isValid()) {
      return m.format(args || 'lll');
    } else {
      return date;
    }
  }
}
