import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IEmail } from '@shared/interfaces/email.interface';
import { EmailsService } from '@shared/services/emails.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';

@Component({
  selector: 'app-modals-emails',
  templateUrl: './modals.emails.component.html'
})
export class ModalsEmailsComponent
  extends ModalDirective<IEmail>
  implements OnInit {
  public title = String('MODALS.EMAILS.ADD.TITLE');
  public description = String('MODALS.EMAILS.ADD.DESCRIPTION');

  public disableButtons = Boolean(false);
  public isEditing = Boolean(false);
  public isOpen = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;

  public entry: FormGroup;

  public modules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link']
    ]
  };

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _emails: EmailsService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _toasts: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe((e: IEmail) => {
      this.isOpen = false;

      if (e) {
        this.title = 'MODALS.EMAILS.EDIT.TITLE';
        this.description = 'MODALS.EMAILS.EDIT.DESCRIPTION';
        this.deleteKey = 'BUTTONS.DELETE';
        this.isEditing = true;

        this.entry.patchValue(e);
      } else {
        this.title = 'MODALS.EMAILS.ADD.TITLE';
        this.description = 'MODALS.EMAILS.ADD.DESCRIPTION';
        this.deleteKey = null;
        this.isEditing = false;
      }

      // Needed for ngx-quill html <p> issue
      // https://stackoverflow.com/questions/54967663/quilljs-merges-subsequent-p-tags-into-one-with-angular-7-ngx-quill
      setTimeout(() => {
        this.isOpen = true;
      });
    });

    this.createForm();
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();
      this.disableButtons = true;

      let method = 'post';
      let url = `${method.toUpperCase()} /emails`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /emails/${value.id}`;
      }

      this._emails[method](value).subscribe(
        (res: IEmail) => {
          this._logger.info(this.constructorName, url, res);

          this._toasts.success('Email updated');

          this.entry.enable();
          this.disableButtons = false;
          this.resetModal();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          if (err.errors) {
            this.errors = err.errors;
          }

          this.entry.enable();
          this.disableButtons = false;

          this._toasts.error('Email update failed');
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      type: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      title: ['', [Validators.required]],
      body: ['', [Validators.required]],
      template: ['', [Validators.required]],
      cta: ['', [Validators.required]],
      url: ['', [Validators.required]]
    });
  }

  private delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /emails/${id}`;

    this._emails.delete(id).subscribe(
      (res: IEmail) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        if (err.errors) {
          this.errors = err.errors;
        }
      }
    );
  }
}
