import { IFilter } from '@shared/interfaces/filter.interface';

export class Filter implements IFilter {
  public key: string;
  public value: string;

  constructor(partial: Partial<IFilter> | string) {
    Object.assign(this, partial);
  }
}
