export const dataURLtoBlob = (dataurl: string) => {
  try {
    let byteString;
    if (dataurl.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataurl.split(',')[1]);
    } else {
      byteString = unescape(dataurl.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  } catch (e) {
    console.error(e);
  }
};
