import { Component, Input, OnInit } from '@angular/core';
import { IError } from '@shared/interfaces/error.interface';

@Component({
  selector: 'app-list-errors',
  templateUrl: './list-errors.component.html'
})
export class ListErrorsComponent implements OnInit {
  @Input() public errors: IError[] = [];

  constructor() {}

  ngOnInit(): void {}
}
