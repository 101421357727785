import { IAction } from '@shared/interfaces/action.interface';
import { IActivity } from '@shared/interfaces/activity.interface';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { User } from '@shared/models/user.model';

export class Activity implements IActivity {
  public action?: Partial<IAction>;
  public checklist?: Partial<IChecklist>;
  public user?: Partial<IUser>;
  public recipient?: Partial<IUser>;

  public ownerId: string;
  public ownerType: string;
  public trackableId: string;
  public trackableType: string;
  public recipientId: string;
  public recipientType: string;

  public key: string;
  public value: string;

  public isDeleted: boolean;

  public createdAt: Date;
  public updatedAt: Date;

  constructor(partial: Partial<IActivity>) {
    Object.assign(this, {
      ...partial,
      user:
        typeof partial?.user === 'object' && partial?.user !== null
          ? new User(partial.user)
          : partial?.user,
      recipient:
        typeof partial?.recipient === 'object' && partial?.recipient !== null
          ? new User(partial.recipient)
          : partial?.recipient
    });
  }

  get description(): string {
    switch (this.key) {
      // Checklists
      case 'checklist.created':
        return `Checklist <b>${this.checklist.label}</b> submitted by ${this.user?.fullName}`;

      case 'checklist.updated':
        return `Checklist <b>${this.checklist.label}</b> updated by ${this.user?.fullName}`;

      case 'checklist.reviewed':
        return `Checklist <b>${this.checklist.label}</b> reviewed by ${this.user?.fullName}`;

      case 'checklist.alert':
        return `Checklist <b>${this.checklist.label}</b> has ${this.value} 'NOs' answers`;

      default:
        return '-';
    }
  }
}
