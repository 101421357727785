import { Injectable } from '@angular/core';
import { IItem } from '@shared/interfaces/item.interface';
import { ItemsService } from '@shared/services/items.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationItemsService extends PaginationService<IItem> {
  constructor(_paginable: ItemsService, _logger: LoggerService) {
    super(_paginable, _logger);
  }
}
