import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { AuthenticatedGuard } from '@shared/guards/authenticated.guard';
import { CustomPreloadingStrategy } from '@shared/strategies/custom.preloading.strategy';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@admin/protected/protected.module').then(
        (m) => m.ProtectedModule
      ),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: '',
    loadChildren: () =>
      import('@admin/public/public.module').then((m) => m.PublicModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      preloadingStrategy: CustomPreloadingStrategy
    })
  ],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
