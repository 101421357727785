import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IContact } from '@shared/interfaces/contact.interface';
import { IDocument } from '@shared/interfaces/document.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Contact } from '@shared/models/contact.model';
import { ContactsSerializer } from '@shared/serializers/contacts.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Document } from '@shared/models/documents';

@Injectable({
  providedIn: 'root'
})
export class ContactsService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/contacts`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<IContact> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Contact(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IContact> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Contact(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public findByEmail(email: string): Observable<IContact> {
    return this._http.get(`${this.endpoint}/email/${email}`).pipe(
      map((res: any) => new Contact(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IContact[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((a: IContact) => new Contact(a))
      ),
      catchError(errorHandler)
    );
  }

  public patch(c: Partial<IContact>): Observable<IContact> {
    const data = ContactsSerializer.serialize(c);
    return this._http.patch(`${this.endpoint}/${c.id}`, data).pipe(
      map((res: any) => new Contact(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(c: Partial<IContact>): Observable<IContact> {
    const data = ContactsSerializer.serialize(c);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Contact(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public getDocuments(id: string): Observable<IDocument[]> {
    return this._http.get(`${this.endpoint}/${id}/documents`).pipe(
      map((res: any) =>
        deserializer.deserialize(res).map((d: IDocument) => new Document(d))
      ),
      catchError(errorHandler)
    );
  }

  public postDocument(file: File, filters: IFilter[]): Observable<IDocument> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    let httpParams = new HttpParams();

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http
      .post(`${this._commonEnvironments.API_ENDPOINT}/pictures`, formData, {
        params: httpParams
      }).pipe(map((res: any) => new Document(deserializer.deserialize(res))));
  }
}
