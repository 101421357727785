import { environment } from '@environment/environment';
import { IAction } from '@shared/interfaces/action.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Location } from '@shared/models/location.model';
import { User } from '@shared/models/user.model';

export class Action implements IAction {
  id: string;
  parent: Partial<ILocation>;
  location: Partial<ILocation>;
  user: Partial<IUser>;

  label: string;
  description: string;
  priority: number;
  status: number;
  pictures: string[];
  isDeleted: boolean;

  completedAt: Date;

  createdAt: Date;
  updatedAt: Date;

  constructor(partial: Partial<IAction>) {
    Object.assign(this, {
      ...partial,
      user: partial.user ? new User(partial.user) : partial.user,
      location: partial.location
        ? new Location(partial.location)
        : partial.location,
      parent:
        partial.location &&
        partial.location.parent &&
        partial.location.parent === 'object'
          ? new Location(partial.location.parent)
          : partial?.location?.parent
    });
  }

  public get picturesWithPath(): string[] {
    return this.pictures.map(() => `${environment.CDN_ENDPOINT}/${this.id}`);
  }

  public get buttonStatus() {
    let label = String('Not verified');
    let icon = String('lnr-cross me-2');
    let className = String('btn-sm ohq-btn-warning');

    if (this.completedAt) {
      label = 'Completed';
      icon = 'lnr-shield-check me-2';
      className = 'btn-sm ohq-btn-success';
    }

    return {
      label,
      icon,
      class: className
    };
  }

  get pillsPriority(): any {
    let label;
    let className;

    switch (this.priority) {
      case 1:
        label = 'Medium';
        className = 'ohq-badge-warning';
        break;

      case 2:
        label = 'High';
        className = 'ohq-badge-danger';
        break;

      case 0:
      default:
        label = 'Low';
        className = 'ohq-badge-light text-dark';
        break;
    }

    return {
      label,
      class: className
    };
  }

  get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }
}
