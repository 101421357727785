import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-confirm',
  templateUrl: './modals.confirm.component.html'
})
export class ModalsConfirmComponent
  extends ModalDirective<boolean>
  implements OnInit
{
  @Input() public openModal: Subject<any>;
  @Input() public title = String('MODALS.CONFIRM.TITLE');
  @Input() public description = String('MODALS.CONFIRM.DESCRIPTION');

  public entry: FormGroup;

  public disableButtons = Boolean(false);
  public deleteKey = String('BUTTONS.DELETE');

  constructor(private readonly _fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe(() => {});

    this.createForm();
  }

  public onSubmit() {
    this.resetModal();
  }

  protected createForm() {
    this.entry = this._fb.group({});
  }
}
