import { companies } from '@shared/serializers/companies.serializer';
import { getSerializer } from '@shared/serializers/serializer';

export const itemGroups = {
  type: 'itemGroup',
  attributes: [
    'company',
    'label',
    'order',
    'isDeleted',
    'createdAt',
    'updatedAt'
  ],
  company: {
    ref: 'id',
    attributes: companies.attributes,
    included: false
  }
};

export const ItemGroupsSerializer = getSerializer(itemGroups);
