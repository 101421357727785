import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private readonly _toastr: ToastrService) {}

  public error(message: string): void {
    this._toastr.error(message, 'Error');
  }

  public success(message: string): void {
    this._toastr.success(message, 'Success');
  }
}
