import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TypeaheadService } from '@shared/services/typeahead.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { noop } from 'rxjs/';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html'
})
export class ModalsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('template', { static: true })
  public template: TemplateRef<any>;

  @Input() public title: string;
  @Input() public description: string;
  @Input() public config = {};

  @Input() public openModal: Subject<any>;
  @Input() public closeModal: Subject<any>;
  @Input() public deleteKey?: string;
  @Input() public invalidFormGroup: boolean;
  @Input() public disableButtons: boolean;
  @Input() public disableDecline?: boolean = Boolean(false);
  @Input() public submitKey?: string;
  @Input() public warningKey: string;

  @Input() public isFooterEnable = Boolean(true);
  @Input() public isShaking = Boolean(false);

  @Input() public onDismiss: (any) => any = noop;
  @Input() public whenModalClose: (any) => any = noop;

  @Output() public goBack: EventEmitter<any> = new EventEmitter();
  @Output() public submitData = new EventEmitter();

  public modalRef: BsModalRef;

  public openModalSubscription: Subscription;
  public closeModalSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    public readonly _typeahead: TypeaheadService
  ) {}

  ngOnInit(): void {
    this.openModalSubscription = this.openModal?.subscribe(() => {
      this._typeahead.isModalOpen(true);
      this.open(this.template);
    });

    this.closeModalSubscription = this.closeModal?.subscribe(() => {
      this._typeahead.isModalOpen(false);
      this.close();
    });

    this.submitData.subscribe( (data) => {
      if(data) {
        alert('submited!');
      }
    });
  }

  ngOnDestroy(): void {
    this.openModalSubscription?.unsubscribe();
    this.closeModalSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    const element: HTMLCollectionOf<Element> = document.getElementsByTagName(
      'modal-container'
    );

    if (element && changes) {
      const modal: Element = element[0];
      const classList = modal && modal.classList;

      if (classList && changes.isShaking && changes.isShaking.currentValue) {
        classList.add('shake');
      } else if (modal && modal.className.indexOf('shake') > -1) {
        classList.remove('shake');
      }
    }
  }

  public close() {
    this._typeahead.isModalOpen(false);
    this.modalRef.hide();
  }

  public open(template: TemplateRef<any>) {
    this.modalService.onHidden.subscribe((reason: string) => {
      this.onDismiss(reason);
    });

    this.modalService.onShown.subscribe(() => {
      const inputFocusable = document.querySelector(
        'modal-container input[appAutoFocus]'
      ) as HTMLElement;

      if (inputFocusable) {
        inputFocusable.focus();
      }
    });

    this.modalRef = this.modalService.show(template, {
      ...this.config,
      ignoreBackdropClick: true
    });
  }
}
