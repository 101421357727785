import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { CommonEnvironmentsService } from '@shared/services/environments.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _router: Router
  ) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const token = this._commonEnvironments.getToken();
    if (!token) {
      this._router.navigate(['/login'], {
        queryParams: {
          returnUrl: state.url
        }
      });

      return false;
    }

    return true;
  }
}
