import { ICompanySetting } from '@shared/interfaces/company-setting.interface';
import { ICompany } from '@shared/interfaces/company.interface';

export class CompanySetting implements ICompanySetting {
  public id: string;
  public company: Partial<ICompany>;

  public isGeolocationEnabled: boolean;
  public isQrCodeEnabled: boolean;
  public isReportEnabled: boolean;
  public isAllowNewInspectionEnabled: boolean;
  public isChecklistsReviewableEnabled: boolean;
  public isReviewAllEnabled: boolean;
  public isWhiteLabelEnabled: boolean;

  constructor(partial: Partial<ICompanySetting>) {
    Object.assign(this, partial);
  }
}
