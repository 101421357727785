import { Injectable } from '@angular/core';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { ChecklistsService } from '@shared/services/checklists.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationEventChecklistsService extends PaginationService<
  IChecklist
> {
  constructor(_paginable: ChecklistsService, _logger: LoggerService) {
    super(_paginable, _logger);
  }
}
