import { locations } from '@shared/serializers/locations.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { users } from '@shared/serializers/users.serializer';

export const tasks = {
  type: 'task',
  attributes: [
    'user',
    'createdBy',
    'location',
    'task',
    'notes',
    'pictures',
    'isSendEmailSelected',
    'description',
    'createdAt',
    'completedAt',
    'updatedAt',
    'checklistId',
    'priority',
    'recurrence',
    'unit',
    'userType',
    'startDate',
    'dueDate',
  ],
  createdBy: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  location: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  },
  // pictures: {
  //   ref: 'id',
  //   attributes: pictures.attributes,
  //   included: false
  // }
};

export const TasksSerializer = getSerializer(tasks);
