import { Notifier } from '@airbrake/browser';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { environment } from '@environment/environment';

export class AirbrakeErrorHandler implements ErrorHandler {
  public airbrake: any;

  private readonly environement: string = String(
    environment.AIRBRAKE.ENVIRONMENT
  );

  constructor() {
    let TYPE = 'APP';
    const href = window.location.href;

    if (href.indexOf(':4000') > -1 || href.indexOf('admin.') > -1) {
      TYPE = 'ADMIN';
    }

    this.airbrake = new Notifier({
      environment: this.environement,
      projectId: environment.AIRBRAKE[TYPE].PROJECT_ID,
      projectKey: environment.AIRBRAKE[TYPE].PROJECT_KEY,
      remoteConfig: false
    });

    const filters = [/loading chunk/gi, /expected expression/gi];

    this.airbrake.addFilter((notice) => {
      const userAgent = notice.context.userAgent;

      // Not loggin anything coming from Edge
      if (userAgent.indexOf('Edge') > -1) {
        return null;
      }

      notice.context.version = environment.VERSION;

      const message = JSON.stringify(notice.errors[0].message);

      for (const f of filters) {
        if (f.test(message)) {
          return null;
        }
      }

      return notice;
    });
  }

  handleError(err: any[] | Error | HttpErrorResponse): void {
    let error: any[] | Error | HttpErrorResponse | string = err;

    if (err instanceof Error && !!err.message) {
      error = err.message;
    } else if (err instanceof Array) {
      error = (err[0] || []).detail || JSON.stringify(err[0]);
    }

    console.error(err);

    if (this.environement !== 'development') {
      this.airbrake.notify(error);
    }
  }
}
