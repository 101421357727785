import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeaheadService {
  public isModalOpenSource: Subject<boolean> = new Subject();
  public isModalOpenCalled$ = this.isModalOpenSource.asObservable();

  constructor() {}

  public isModalOpen(isOpen: boolean) {
    this.isModalOpenSource.next(isOpen);
  }
}
