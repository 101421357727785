import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICompany } from '@shared/interfaces/company.interface';
import { CompaniesService } from '@shared/services/companies.service';
import { LoggerService } from '@shared/services/logger.service';

@Component({
  selector: 'app-modals-companies',
  templateUrl: './modals.companies.component.html'
})
export class ModalsCompaniesComponent
  extends ModalDirective<ICompany>
  implements OnInit {
  public title = String('MODALS.COMPANIES.ADD.TITLE');
  public description = String('MODALS.COMPANIES.ADD.DESCRIPTION');

  public disableButtons = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');

  public entry: FormGroup;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _ar: ActivatedRoute,
    private readonly _companies: CompaniesService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe((c: ICompany) => {
      if (c) {
        this.title = 'MODALS.COMPANIES.EDIT.TITLE';
        this.description = 'MODALS.COMPANIES.EDIT.DESCRIPTION';

        this.entry.patchValue(c);
      } else {
        this.title = 'MODALS.COMPANIES.ADD.TITLE';
        this.description = 'MODALS.COMPANIES.ADD.DESCRIPTION';
      }
    });

    this.createForm();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();
      this.disableButtons = true;

      let method = 'post';
      let url = `${method.toUpperCase()} /companies`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /companies/${value.id}`;
      }

      this._companies[method](value).subscribe(
        (res: ICompany) => {
          this._logger.info(this.constructorName, url, res);

          if (method === 'post') {
            this._router.navigate([res.id], { relativeTo: this._ar });
          }

          this.entry.enable();
          this.disableButtons = false;

          this.resetModal(res);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          if (err.errors) {
            this.errors = err.errors;
          }

          this.disableButtons = false;
          this.entry.enable();
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      name: ['', [Validators.required]]
    });
  }
}
