import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class CommonEnvironmentsService {
  public readonly API_ENDPOINT: string = String(environment.API_ENDPOINT);
  public readonly IS_PRODUCTION: boolean = Boolean(environment.IS_PRODUCTION);
  public readonly VERSION: string = String(environment.VERSION);
  public readonly ENVIRONMENT: string = String(environment.ENVIRONMENT);
  public readonly CDN_ENDPOINT: string = String(environment.CDN_ENDPOINT);
  public readonly TZ: string = String(environment.TZ);

  constructor(
    private readonly _cookies: CookieService,
    private readonly _ls: LocalStorageService,
    private readonly _ss: SessionStorageService
  ) {}

  public getToken(token?: string) {
    if (!token) {
      const href = window.location.href;
      token = 'token';

      if (href.indexOf(':4000') > -1 || href.indexOf('admin.') > -1) {
        token = 'admin-token';
      }
    }

    return this._cookies.get(token);
  }

  public setToken(label: string, token: string) {
    this._cookies.set(label, token, {
      expires: 15,
      path: '/',
      sameSite: 'Strict',
      secure: false
    });
  }

  public cleanStorage() {
    this._ls.clear();
    this._ss.clear();
    this._cookies.deleteAll('/');
  }
}
