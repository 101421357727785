import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _router: Router
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._commonEnvironments.getToken();

    if (!!token) {
      const authorization = {
        Authorization: `Bearer ${token}`
      };

      request = request.clone({
        setHeaders: {
          ...authorization
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        // Operation failed; error is an HttpErrorResponse
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            const status = err.status;

            if (status === 0 || status === 401 || status === 403) {
              this._commonEnvironments.cleanStorage();
              this._router.navigate(['/login']);
            }
          }
        }
      )
    );
  }
}
