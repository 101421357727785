import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICountry } from '@shared/interfaces/country.interface';
import { Country } from '@shared/models/country.model';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html'
})
export class PhoneNumberComponent implements OnInit {
  @SessionStorage() public countries: ICountry[];

  @Input() public formGroup: FormGroup;
  @Input() public language = String('en');
  @Input() public explainer = String('');

  public mask = '00000000000000000000';

  private defaultCountry: ICountry;

  constructor() {}

  ngOnInit(): void {
    this.defaultCountry =
      this.countries?.find((c: ICountry) => c.initial === 'CA') ||
      new Country({
        initial: 'CA',
        countryCode: '1'
      });

    const country: ICountry =
      this.countries.find((c: ICountry) => c.initial === 'CA') ||
      this.defaultCountry;

    return this.setCountryCode(country);
  }

  public updateCountryCode() {
    const countryCode = this.formGroup.value.countryCode;
    let country = this.countries.find(
      (c: ICountry) => c.countryCode === countryCode
    );

    if (!country) {
      country = this.defaultCountry;
    }

    return this.formGroup.patchValue({
      countryCode: country.countryCode
    });
  }

  private setCountryCode(c: ICountry): void {
    return this.formGroup.patchValue({
      countryCode: c.countryCode
    });
  }
}
