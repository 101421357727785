import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IError } from '@shared/interfaces/error.interface';
import { IItemGroup } from '@shared/interfaces/item-group.interface';
import { IItem } from '@shared/interfaces/item.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { ItemGroupsService } from '@shared/services/item-groups.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import jwtDecode from 'jwt-decode';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-item-groups',
  templateUrl: './modals.item-groups.component.html'
})
export class ModalsItemGroupsComponent
  extends ModalDirective<IItemGroup>
  implements OnInit
{
  @Input() public openModal: Subject<IItemGroup>;

  public title = String('MODALS.ITEM_GROUPS.ADD.TITLE');
  public description = String('MODALS.ITEM_GROUPS.ADD.DESCRIPTION');
  public entry: FormGroup;

  public disableButtons = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;

  public errors: IError[] = [];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _itemGroups: ItemGroupsService,
    private readonly _toasts: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((ig: IItemGroup) => {
      const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());

      if (!!ig) {
        this.title = 'MODALS.ITEM_GROUPS.EDIT.TITLE';
        this.description = 'MODALS.ITEM_GROUPS.EDIT.TITLE';
        this.deleteKey = 'BUTTONS.DELETE';

        this.entry.patchValue(ig);
      } else {
        this.deleteKey = null;
      }

      this.entry.patchValue({
        company: {
          id: payload.company
        }
      });
    });
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /item-groups/${id}`;

    this._itemGroups.delete(id).subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /items`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /items/${value.id}`;
      }

      this._itemGroups[method](value).subscribe(
        (res: IItem) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal();
          this.entry.enable();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          const errors = err.errors;

          if (errors) {
            this.errors = errors;

            if (errors[0].detail) {
              this._toasts.error(errors[0].detail);
            }
          }

          this.entry.enable();
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      label: ['', Validators.required],
      order: [0, Validators.required],
      company: this._fb.group({
        id: ['', Validators.required]
      })
    });
  }
}
