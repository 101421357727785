import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IDocument } from '@shared/interfaces/document.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Document } from '@shared/models/documents';
import { Location } from '@shared/models/location.model';
import { LocationsSerializer } from '@shared/serializers/locations.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationsService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/locations`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<ILocation> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: ILocation) => new Location(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<ILocation> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: ILocation) => new Location(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public getDocuments(id: string): Observable<IDocument[]> {
    return this._http.get(`${this.endpoint}/${id}/documents`).pipe(
      map((res: any) =>
        deserializer.deserialize(res).map((d: IDocument) => new Document(d))
      ),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<ILocation[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((l: ILocation) => new Location(l))
      ),
      catchError(errorHandler)
    );
  }

  public getSubLocations(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<ILocation[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(`${this.endpoint}/sublocations`, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((l: ILocation) => new Location(l))
      ),
      catchError(errorHandler)
    );
  }

  public patch(l: ILocation): Observable<ILocation> {
    const data = LocationsSerializer.serialize(l);
    return this._http.patch(`${this.endpoint}/${l.id}`, data).pipe(
      map((res: ILocation) => new Location(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(l: ILocation): Observable<ILocation> {
    const data = LocationsSerializer.serialize(l);
    return this._http.post(this.endpoint, data).pipe(
      map((res: ILocation) => new Location(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public postDocument(file: File, filters: IFilter[]): Observable<IDocument> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    let httpParams = new HttpParams();

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http
      .post(`${this._commonEnvironments.API_ENDPOINT}/pictures`, formData, {
        params: httpParams
      })
      .pipe(map((res: any) => new Document(deserializer.deserialize(res))));
  }
}
