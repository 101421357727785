import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IPagination } from '@shared/interfaces/pagination.interface';
import { Pagination } from '@shared/models/pagination.model';
import { LoggerService } from '@shared/services/logger.service';
import { BehaviorSubject, Observable } from 'rxjs';

export class PaginationService<T> {
  public readonly _pageBehavior: BehaviorSubject<IPagination> =
    new BehaviorSubject<IPagination>(new Pagination<T>({}));
  public page: Observable<any> = this._pageBehavior.asObservable();

  public pages: Map<number, IPagination> = new Map<number, IPagination>();

  public pagination: Partial<IPagination> = {
    page: 1
  };

  public filters: IFilter[];

  private _lastRequestedPageNumber = Number(0);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _paginable: Partial<IPaginable>,
    private readonly _logger: LoggerService
  ) {
    this.onGetRawData = this.onGetRawData.bind(this);
  }

  public checkAndLoadPage(pageNumber: number) {
    this._lastRequestedPageNumber = pageNumber;

    if (this.pages.has(pageNumber)) {
      this.setPage(this.pages.get(pageNumber));
      return;
    }

    if (!this.pages.has(pageNumber)) {
      const page = new Pagination<T>({ page: pageNumber });
      this.setPage(page);
      this.loadPage(pageNumber);
    }
  }

  public reloadPage(pageNumber: number) {
    if (this.pages.has(pageNumber)) {
      this.pages.delete(pageNumber);
    }

    const page = new Pagination<T>({ page: pageNumber });
    this.setPage(page);
    this._lastRequestedPageNumber = pageNumber;
    this.loadPage(pageNumber);
  }

  public setFilters(filters: any[]) {
    this.filters = filters;
  }

  public getFilters(): IFilter[] {
    return this.filters;
  }

  public reset() {
    this.setFilters([]);
    this.pages = new Map<number, IPagination>();

    this.pagination = {
      page: 1
    };
  }

  public fullReload() {
    this.reset();
    this.checkAndLoadPage(1);
  }

  private onGetRawData(res: any) {
    const links = res.links;
    if (links) {
      this.pagination = links.pagination;
    }
  }

  private loadPage(pageNumber: number) {
    const url = `GET page ${pageNumber} loaded`;
    this._paginable.get(pageNumber, this.filters, this.onGetRawData).subscribe(
      (res: any[]) => {
        const page = new Pagination<T>({
          page: pageNumber,
          data: res,
          isLoading: false
        });

        this._logger.info(this.constructorName, url, page.data);

        this.pages.set(pageNumber, page);
        this.setPage(page);

        if (this._lastRequestedPageNumber === pageNumber) {
          this._lastRequestedPageNumber = 0;
        }
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private setPage(page: IPagination) {
    this.pages.set(page.page, page);

    if (this._lastRequestedPageNumber === page.page) {
      this._pageBehavior.next(page);
    }
  }
}
