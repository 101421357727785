import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IEmployee } from '@shared/interfaces/employee.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Employee } from '@shared/models/employee.model';
import { EmployeesSerializer } from '@shared/serializers/employees.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/employees`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<IEmployee> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Employee(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IEmployee> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Employee(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IEmployee[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((e: IEmployee) => new Employee(e))
      ),
      catchError(errorHandler)
    );
  }

  public patch(e: Partial<IEmployee>): Observable<IEmployee> {
    const data = EmployeesSerializer.serialize(e);
    return this._http.patch(`${this.endpoint}/${e.id}`, data).pipe(
      map((res: any) => new Employee(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(e: IEmployee): Observable<IEmployee> {
    const data = EmployeesSerializer.serialize(e);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Employee(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }
}
