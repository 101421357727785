import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Setting } from '@shared/models/setting.model';
import { User } from '@shared/models/user.model';
import { UsersSerializer } from '@shared/serializers/users.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/users`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<IUser> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new User(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IUser> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new User(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public getByEmail(email: string): Observable<IUser> {
    return this._http.get(`${this.endpoint}/email/${email}`).pipe(
      map((res: any) => new User(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public getMyself(): Observable<IUser> {
    return this._http.get(`${this.endpoint}/me`).pipe(
      map((res: any) => new User(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IUser[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((u: IUser) => new User(u))
      ),
      catchError(errorHandler)
    );
  }

  public getUserSettings(id: string): Observable<ISetting> {
    return this._http.get(`${this.endpoint}/${id}/settings`).pipe(
      map((res: any) => new Setting(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public patch(user: Partial<IUser>): Observable<IUser> {
    const data = UsersSerializer.serialize(user);
    return this._http.patch(`${this.endpoint}/${user.id}`, data).pipe(
      map((res: any) => new User(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(user: IUser): Observable<IUser> {
    const data = UsersSerializer.serialize(user);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new User(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }
}
