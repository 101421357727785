import { environment } from '@environment/environment';
import { phoneFormat } from '@shared/helpers/phone-formart.helper';
import { IPicture } from '@shared/interfaces/picture.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Setting } from '@shared/models/setting.model';
import { PhoneNumber } from 'libphonenumber-js';

export class User implements IUser {
  public id: string;

  public setting: Partial<ISetting>;

  public email: string;
  public phone: PhoneNumber;
  public countryCode: string;
  public picture: Partial<IPicture>;
  public signature: Partial<IPicture>;
  public firstName: string;
  public lastName: string;
  public role: number;

  public token: string;

  public connectedAt?: Date;
  public isDeleted: boolean;

  constructor(partial: Partial<IUser>) {
    Object.assign(this, {
      ...partial,
      setting:
        typeof partial?.setting === 'object'
          ? new Setting(partial.setting)
          : partial?.setting
    });
  }

  get pictureUrl(): string {
    const endpoint = environment.CDN_ENDPOINT;
    return this.picture
      ? `${endpoint}/${this.picture.id || this.picture['Id']}`
      : `${endpoint}/images/pictures/user.png`;
  }

  get signatureUrl(): string {
    const endpoint = environment.CDN_ENDPOINT;
    return this.signature
      ? `${endpoint}/${this.signature.id || this.signature['Id']}`
      : `${endpoint}/images/pictures/signature.png`;
  }

  get fullName(): string {
    return this.firstName || this.lastName
      ? `${this.firstName} ${this.lastName}`
      : null;
  }

  get phoneNumber(): string {
    return phoneFormat(this.countryCode, this.phone);
  }

  get pillsRole(): any {
    let label;
    let className;

    switch (this.role) {
      case 0:
        label = 'Root';
        className = 'ohq-badge-danger';
        break;

      case 1:
        label = 'User';
        className = 'ohq-badge-success';
        break;

      default:
        label = '-';
        className = 'ohq-badge-primary';
        break;
    }

    return {
      label,
      class: className
    };
  }

  get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }
}
