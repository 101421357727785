import { IPagination } from '@shared/interfaces/pagination.interface';

export class Pagination<T> implements IPagination {
  public total: number;
  public page: number = Number(1);
  public pages: number;
  public offset: number;
  public limit: number = Number(15);
  public data: T[] = [];
  public isLoading: boolean = Boolean(true);

  constructor(data: any) {
    Object.assign(this, data);
  }
}
