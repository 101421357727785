import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IAction } from '@shared/interfaces/action.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Action } from '@shared/models/action.model';
import { ActionsSerializer } from '@shared/serializers/actions.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActionsService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/actions`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<IAction> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Action(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IAction> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Action(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IAction[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((a: IAction) => new Action(a))
      ),
      catchError(errorHandler)
    );
  }

  public patch(a: Partial<IAction>): Observable<IAction> {
    const data = ActionsSerializer.serialize({
      ...a,
      isSendEmailSelected: (a.user as any).isSendEmailSelected
    });

    return this._http.patch(`${this.endpoint}/${a.id}`, data).pipe(
      map((res: any) => new Action(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(a: Partial<IAction>): Observable<IAction> {
    const data = ActionsSerializer.serialize({
      ...a,
      isSendEmailSelected: (a.user as any).isSendEmailSelected
    });

    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Action(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }
}
