import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IItem } from '@shared/interfaces/item.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { ITemplate } from '@shared/interfaces/template.interface';
import { Item } from '@shared/models/item.model';
import { Template } from '@shared/models/template.model';
import { TemplatesSerializer } from '@shared/serializers/templates.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/templates`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<ITemplate> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Template(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<ITemplate> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Template(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<ITemplate[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((t: ITemplate) => new Template(t))
      ),
      catchError(errorHandler)
    );
  }

  public patch(t: Partial<ITemplate>): Observable<ITemplate> {
    const data = TemplatesSerializer.serialize(t);
    return this._http.patch(`${this.endpoint}/${t.id}`, data).pipe(
      map((res: any) => new Template(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(t: Partial<ITemplate>): Observable<ITemplate> {
    const data = TemplatesSerializer.serialize(t);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Template(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public postItems(id: string, file: File): Observable<IItem[]> {
    const formData: FormData = new FormData();
    formData.append('file', file, uuid());

    return this._http.post(`${this.endpoint}/${id}/items`, formData).pipe(
      map((res: any) =>
        deserializer.deserialize(res).map((ii: IItem) => new Item(ii))
      ),
      catchError(errorHandler)
    );
  }
}
