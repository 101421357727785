import { IDocument } from '@shared/interfaces/document.interface';

export class Document implements IDocument {
  public id: string;
  public ownerId: string;
  public ownerType: string;
  public type: string;
  public reference: string;
  public size: number;
  public filename: string;

  constructor(partial: Partial<IDocument>) {
    Object.assign(this, partial);
  }

  public get buttonDelete() {
    return {
      label: '',
      icon: 'lnr-cross',
      class: 'btn-sm ohq-btn-danger'
    };
  }
}
