import { IAuthentication } from '@shared/interfaces/authentication.interface';

export class Authentication implements IAuthentication {
  public token: string;
  public resetToken: string;

  constructor(partial: Partial<IAuthentication>) {
    Object.assign(this, partial);
  }
}
