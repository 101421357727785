import { ICompany } from '@shared/interfaces/company.interface';
import { IItem } from '@shared/interfaces/item.interface';
import { ITemplate } from '@shared/interfaces/template.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Item } from '@shared/models/item.model';
import { User } from '@shared/models/user.model';

export class Template implements ITemplate {
  public id: string;

  public company: Partial<ICompany>;
  public user: Partial<IUser>;

  public label: string;
  public language: string;
  public items: IItem[];
  public itemsCount: number;
  public isDeleted: boolean;

  public createdAt: Date;
  public updatedAt: Date;

  get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }

  constructor(partial: Partial<ITemplate>) {
    Object.assign(this, {
      ...partial,
      items:
        partial?.items !== null && typeof partial?.items === 'object'
          ? partial.items.map((i: IItem) => new Item(i))
          : [],
      user:
        typeof partial?.user === 'object'
          ? new User(partial.user)
          : partial?.user
    });
  }
}
