import { getSerializer } from '@shared/serializers/serializer';

export const emails = {
  type: 'email',
  attributes: [
    'type',
    'subject',
    'title',
    'body',
    'template',
    'cta',
    'url',
    'createdAt',
    'updatedAt'
  ]
};

export const EmailsSerializer = getSerializer(emails);
