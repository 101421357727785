import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html'
})
export class LoadingComponent implements OnInit {
  @Input() public isLoading: boolean;
  @Input() public isFullSize: boolean = Boolean(true);
  @Input() public message?: string;
  @Input() public isChart: boolean = Boolean(false);
  @Input() public isSmallChart: boolean = Boolean(false);

  constructor() {}

  ngOnInit(): void {}
}
